// import { ReactComponent as SKRPIcon } from "../../assets/images/scrapping.svg";
import altCert from "../../assets/images/certificates/alteryx_alt_cert.png";
import dcCert from "../../assets/images/certificates/datascience_dcp_cert.png";
import langchainCert from "../../assets/images/certificates/langchain_cert.png";
import craCert from "../../assets/images/certificates/tensorflow_crs_cert.jpeg";

import altLogo from "../../assets/images/certificates/alteryx_logo.png";
import crsLogo from "../../assets/images/certificates/coursera_logo.png";
import dcLogo from "../../assets/images/certificates/datacamp_logo.svg";
import langchainLogo from "../../assets/images/certificates/langchain_logo.png";

export const certificatesData = [
	{
		id: 1,
		name: "Langchain Academy - Introduction to LangGraph",
		year: "2025",
		logo: langchainLogo,
		logoAlt: "Langchain Academy",
		logoBg: "rgb(28,60,60)",
		url: "",
		image: langchainCert,
	},
	{
		id: 2,
		name: "DataCamp - Data Science using Python",
		year: "2020",
		logo: dcLogo,
		logoAlt: "Data Camp",
		logoBg: "rgb(0,28,52)",
		url: "",
		image: dcCert,
	},
	{
		id: 3,
		name: "Alteryx Designer core",
		year: "2020",
		logo: altLogo,
		logoAlt: "Alteryx",
		logoBg: "rgb(247,247,247)",
		url: "",
		image: altCert,
	},
	{
		id: 4,
		name: " Introduction to Tensorflow",
		year: "2020",
		logo: crsLogo,
		logoAlt: "Coursera",
		logoBg: "rgb(42, 115, 204)",
		url: "",
		image: craCert,
	},
];
